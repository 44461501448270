<script>
import { workerService } from '../../../helpers/backend/worker.service';
  export default {
    data() {
      return {
        form: {
          id : null,
          worker_name: '',
        },
        busy:false,
        show: true,
        showDismissibleAlert : false,
        error : "",
      }
    },
    computed: {
      isUpdateState() {
        return this.form.id != null;
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.busy = true;
        workerService.createOrUpdate(this.form).then (
          data=> {
              if (data!= null) {
                this.reset();
                this.$emit('refresh');
                this.showDismissibleAlert =  true
                this.error = "";
              }
              this.busy = false;
          }, 
          error=> {
              this.showDismissibleAlert =  true
              this.error = "Failed to Insert/Update Worker. Error : " + error
              this.busy = false;
          }
        )
      },
      reset(){
        // Reset our form values
        this.form.id = null
        this.form.worker_name = ''
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.showDismissibleAlert = false
        this.error = ""
        this.$nextTick(() => {
          this.show = true
        })
      },
      onReset(event) {
        event.preventDefault()
        this.reset();
      },
      initUpdate(id){
        this.reset();
        this.$refs.worker_name.$el.focus();
        this.busy = true;
        workerService.getById(id).then(
          data=> {
            if (data.worker != null) {
                this.form.id = data.worker.ID
                this.form.worker_name = data.worker.worker_name
            }else {
                this.showDismissibleAlert = true
                this.error = "Failed to get worker to update. Error : Worker not found"
            }
            this.busy = false;
          }, 
          err=> {
            this.showDismissibleAlert = true
            this.error = "Failed to get worker type to update. Error : " + err
            this.busy = false;
          }
        )
      },
    }
  }
</script>


<template>
<div>
  <b-alert
      :variant="error=='' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert=false"
    >
      <div v-if="error != ''">
        {{error}}
      </div>
      <div v-else>
          Success perform Insert/Update action
        </div>
    </b-alert>
  <div class="card">
    <div class="card-body">
      <div>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
       <b-overlay :show="busy" rounded="lg" opacity="0.6">
      <b-form-group
        id="input-group-1"
        label="Worker Name:"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="form.worker_name"
          type="text"
          placeholder="Worker Name"
          required
          ref="worker_name"
        ></b-form-input>
      </b-form-group>
      


      <b-button type="submit" variant="primary">{{isUpdateState ? "Update" : "Submit"}}</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
       </b-overlay>
    </b-form>
    
  </div>
    </div>
  </div>
</div>
</template>



